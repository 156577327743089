.built-by-pic {
  max-width: 320px;
  height: 320px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.built-by-pic-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.built-by-edu {
  width: 320px;
  background-color: black;
  justify-content: center;
}

.built-by-container {
  min-height: 442px;
}

@media screen and (max-width: 1215px) {
  .built-by-edu {
    height: 304px;
  }
}
