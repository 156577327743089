.signup-container {
  display: flex;
  margin-bottom: 0.25em;
}

.signup-form {
  height: 2.9rem !important;
  margin-right: 0.35em;
  min-width: 200px;
}

.signup-form-icon {
  font-size: 1.2em !important;
  width: 2.2em !important;
  height: 2.6em !important;
}

.recaptcha {
  margin-top: 0.5em;
}

@media screen and (max-width: 500px) {
  .signup-container {
    flex-flow: column;
  }
  .hero-signup-cta {
    margin-top: 0.5em;
  }
}
