.App {
  text-align: center;
}

html,
body {
  height: auto;
  width: 100%;
}

.video-hero-container {
  border-radius: 5px;
  margin-top: 50px;
  margin-bottom: 100px;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.3),
    0 0px 0 1px rgba(10, 10, 10, 0.11);
}

@media screen and (max-width: 600px) {
  .video-hero-container {
    display: none;
  }
}

.centered-container {
  display: flex;
  align-items: center;
}

input ::placeholder {
  color: #000;
  opacity: 1;
}

input ::-webkit-input-placeholder {
  color: #000;
  opacity: 1;
}
